import {
  AuthServiceV4,
  CashFlowService,
  ChatBotService,
  DeliveryMenService,
  DiscountCodeService,
  FeaturesService,
  HttpClientConfig,
  IHttpClient,
  IndicatorsService,
  NFService,
  OrderService,
  PaymentSettingsService,
  PostsService,
  PrintGroupSettingsService,
  SurveyService,
  UserNotificationService,
  UserPermissionService,
} from '@wls-solucoes/lets-eat-services';
import axios, { AxiosInstance } from 'axios';
import Parser from 'ua-parser-js';
import { env } from '../../env';
import addAppInfoToHeadersInterceptor from '../interceptors/addAppInfoToHeadersInterceptor';
import AuthInterceptor from '../interceptors/authInterceptor';
import { errorInterceptor } from '../interceptors/errorInterceptor';
import {
  successMessageRequestInterceptor,
  successMessageResponseInterceptor,
} from '../interceptors/successMessageInterceptor';

class AxiosAdapter implements IHttpClient {
  Api: AxiosInstance;

  constructor() {
    const parser = new Parser();
    const { browser } = parser.getResult();

    this.Api = axios.create({ baseURL: env.REACT_APP_API_URL });

    this.Api.defaults.headers = {
      'x-api-version': 2,
      'x-platform-name': browser.name,
      'x-app-version': env.REACT_APP_VERSION,
    } as any;
    this.Api.interceptors.request.use(AuthInterceptor);
    this.Api.interceptors.request.use(addAppInfoToHeadersInterceptor);
    this.Api.interceptors.request.use(successMessageRequestInterceptor);
    this.Api.interceptors.response.use(
      successMessageResponseInterceptor,
      errorInterceptor
    );
  }

  async get(url: string, config?: HttpClientConfig) {
    return this.Api.get(url, config);
  }

  async post(url: string, data?: any, config?: HttpClientConfig) {
    return this.Api.post(url, data, config);
  }

  async put(url: string, data?: any, config?: HttpClientConfig) {
    return this.Api.put(url, data, config);
  }

  async patch(url: string, data?: any, config?: HttpClientConfig) {
    return this.Api.patch(url, data, config);
  }

  async delete(url: string, config?: HttpClientConfig) {
    return this.Api.delete(url, config);
  }
}

const axiosAdapter = new AxiosAdapter();

export const cashFlowService = new CashFlowService(axiosAdapter);
export const surveyService = new SurveyService(axiosAdapter);
export const userPermissionService = new UserPermissionService(axiosAdapter);
export const discountCodeService = new DiscountCodeService(axiosAdapter);
export const printGroupSettingsService = new PrintGroupSettingsService(
  axiosAdapter
);
export const userNotificationService = new UserNotificationService(
  axiosAdapter
);
export const orderService = new OrderService(axiosAdapter);
export const deliveryMenService = new DeliveryMenService(axiosAdapter);
export const chatBotService = new ChatBotService(axiosAdapter);
export const featuresService = new FeaturesService(axiosAdapter);
export const indicatorsService = new IndicatorsService(axiosAdapter);
export const postsService = new PostsService(axiosAdapter);
export const paymentSettingsService = new PaymentSettingsService(axiosAdapter);
export const authServiceV4 = new AuthServiceV4(axiosAdapter);
export const nfService = new NFService(axiosAdapter);

const { Api } = axiosAdapter;

export default Api;
