import { MutationOptions, QueryClient } from '@tanstack/react-query';

export const executeMutation = <TVariables>(
  queryClient: QueryClient,
  options: MutationOptions<any, any, TVariables, any>,
  variables: TVariables
) => {
  return queryClient
    .getMutationCache()
    .build(queryClient, options)
    .execute(variables);
};
