import { UpdateUserPermissionRequestData } from '@wls-solucoes/lets-eat-types';
import { userPermissionService } from '../../services/api';
import { makeMutation } from '../utils/makeMutation';

export const useUserPermissionUpdatePermissions = makeMutation({
  mutationKey: ['userPermissionUpdatePermissions'],
  mutationFn: async ({
    userGuid,
    data,
  }: {
    userGuid: string;
    data: UpdateUserPermissionRequestData[];
  }) => {
    return userPermissionService.updatePermissions(userGuid, data);
  },
});

export const useUserPermissionGetAll = makeMutation({
  mutationKey: ['userPermissionGetAll'],
  mutationFn: async () => {
    return userPermissionService.getAll();
  },
});

export const useUserPermissionGetByUserGuid = makeMutation({
  mutationKey: ['userPermissionGetByUserGuid'],
  mutationFn: async (userGuid: string) => {
    return userPermissionService.getByUserGuid(userGuid);
  },
});
