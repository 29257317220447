import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { executeMutation } from './executeMutation';
import { queryClient } from '../../../App';

type makeMutationReturn<
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown,
  Options = UseMutationOptions<TData, TError, TVariables, TContext>
> = {
  (): UseMutationResult<TData, TError, TVariables, TContext>;
  options: Options;
  execute: (variables?: TVariables) => Promise<TData>;
};

export const makeMutation = <
  TData = unknown,
  TError = DefaultError,
  TVariables = void,
  TContext = unknown
>(
  options: UseMutationOptions<TData, TError, TVariables, TContext>
): makeMutationReturn<TData, TError, TVariables, TContext> => {
  const Fc = () => {
    const mutate = useMutation<TData, TError, TVariables, TContext>(options);
    return mutate;
  };

  const dataToReturn = Fc as any as makeMutationReturn<
    TData,
    TError,
    TVariables,
    TContext
  >;
  dataToReturn.options = options;
  dataToReturn.execute = async (variables): Promise<TData> => {
    return executeMutation(queryClient, options, variables as TVariables);
  };

  return dataToReturn;
};
