import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

export const successMessageResponseInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  const { successMessage } = response.config.headers;
  const hasSuccessMessage = successMessage !== undefined;
  if (hasSuccessMessage) {
    toast.success(decodeURI(successMessage));
  }
  return response;
};

export const successMessageRequestInterceptor = (
  config: InternalAxiosRequestConfig<any>
): InternalAxiosRequestConfig<any> => {
  const { headers } = config as any;
  const { successMessage } = headers;
  if (successMessage !== undefined) {
    headers.successMessage = encodeURI(successMessage);
  }
  return config;
};
